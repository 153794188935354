import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function LoadWhenVisible( { offset, children } ) {

  const [ ref, inView, entry ] = useInView( {
    threshold: 0,
    rootMargin: offset || '500px',
    triggerOnce: true,
  } );
  const [ isLoaded, setIsLoaded ] = React.useState( false );

  React.useEffect( () => {

    if( !! inView && ! isLoaded ) {
      setIsLoaded( true );
    }
  }, [ inView ] );


  return(
    <div ref={ ref }>
      { !! isLoaded && children }
    </div>
  )

}