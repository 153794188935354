
import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import Image from 'molecules/Image';
import Link from 'molecules/Link';
import LoadWhenVisible from 'molecules/LoadWhenVisible';
import SEO from 'molecules/SEO';
import ShareButtons from 'molecules/ShareButtons';
import WithBGImage from 'molecules/WithBGImage';

import { researchMetaFields } from 'lib/fragments';

import './SingleResearch.scss';

//import LineChart from 'organisms/LineChart';

const LineChart = loadable( () => import( 'organisms/LineChart' ) );
const ModalVideo = loadable( () => import( 'molecules/ModalVideo' ) );

const BlockRenderer = p => {

  const { style = 'normal' } = p.node;

  if( style === 'xs' || style == 'sm' || style === 'lg' || style === 'xl'  ) {

    return (
      <p className={ `p-${style}` }>
        { p.children }
      </p>
    )
  }

  if( /^h\d/.test( style ) ) {

    const level = style.replace( /[^\d]/g, '' );
    let size = 'md';

    if( level === '3' ) {
      size = 'sm';
    }
    else if( level === '4' ) {
      size = 'xs';
    }
    else if( level === '5' ) {
      size = 'xs';
    }
    else {
      size = 'xs';
    }

    return React.createElement( style, { className: `h-${size}`}, p.children );

  }


  if( style === 'blockquote' ) {
    return <blockquote>{ p.children }</blockquote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block( p );

}

const contentSerializers = {
  types: {
    block: BlockRenderer,
    pageImage: p => {

      return(
        <Image
        alt={ p.node.altText }
        data={ p.node.image.asset }
        />
      );


    }
  },
}

/**
 * Single Research Article.
 */
export const query = graphql`
  query ResearchArticle( $id: String ) {
    sanityResource( id: { eq: $id } ){
      datePublished( formatString: "MMMM Do, YYYY" )
      charts: _rawCharts
      intro {
        preheading
        summary
        background {
          asset {
            metadata {
              lqip
            }
            url
          }
        }
      }
      deeper {
        boxes {
          ctaText
          heading
          image {
            asset {
              url
              metadata {
                lqip
              }
            }
          }
          url
          text
        }
        heading
      }
      disclaimers: _rawDisclaimers( resolveReferences: { maxDepth: 3 } )
      download: _rawDownload( resolveReferences: { maxDepth: 3 } )
      optionalDownload: _rawOptionalDownload( resolveReferences: { maxDepth: 3 } )
      slug {
        current
      }
      title
      video: _rawVideo( resolveReferences: { maxDepth: 3 } )
      ...researchMetaFields
    }
    sharing: sanitySettingsContact {
      facebookSharing
      emailSharing
      linkedinSharing
      twitterSharing
    }
  }
`;
export default function SingleResearch( { data, path } ) {

  const  { sanityResource: article, sharing } = data;

  console.log( { article } );


  // Chart stuff.
  const yFormat = value => `${value}%`;
  let chartProps = null;
  let xTickValues = [];

  if( !! article.charts && !! article.charts.lineChart ) {

    const { lineChart: chart } = article.charts;
    const rows = JSON.parse( chart.data.code );
    const data = [];
    for( let member of chart.dataMembers ) {
      data.push( {
        id: member.name,
        color: member.color.hex,
        data: [],
      } );
    }

    for( let row of rows ) {

      const xValue = row[0];
      xTickValues.push( xValue );

      let i = 0;

      for( let yValue of row ) {

        if( i === 0 ) {
          i++;
          continue;
        }


        data[i-1].data.push( {
          'x': xValue,
          'y': parseFloat( yValue ),
        } );

        i++;

      }

    }

    // Bah this is a hack.
    const reverseData = data.reverse();

    chartProps = {
      data: reverseData,
      xFormat: "time:%Y-%m-%d %H:%M:%S",
      xScale:{
         type: 'time',
         format: '%Y-%m-%d %H:%M:%S',
         precision: 'minute'
      },
      axisBottom: {
        orient: 'bottom',
        format: "%Y",
        //tickSize: 10,
        tickPadding: 10,
        tickRotation: -90,
        tickValues: 'every 1 year',
        //legend: chart.xLegend,
        legendOffset: 36,
        legendPosition: 'middle'
      },
      axisLeft: {
        orient: 'left',
        format: yFormat,
        fontSize: '20px',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        //legend: 'count',
        legendOffset: -40,
        legendPosition: 'middle'
      },
      enableSlices: 'x',
      sliceTooltip: ( { slice } ) => {

        return(

          <div className="Research__charts-slice">

            { slice.points.map( point => {

              return(

                <div
                key={ point.id }
                className="point"
                >
                  <i style={ { backgroundColor: point.serieColor } } /> <strong>{ point.serieId }:</strong> { Math.round( point.data.yFormatted ) }%
                </div>
              )
            } ) }

          </div>

        );

      },

    }

  }

  /* ------ = Videos. = --------------------------------------------------------------------- */
  const [ isShowingPreview, setIsShowingPreview ] = React.useState( false );
  const [ isOpenPreview, setIsOpenPreview ] = React.useState( false );
  const [ isShowingVideo, setIsShowingVideo ] = React.useState( false );

  React.useEffect( () => {

    if( typeof window !== 'undefined' ) {

      const queryString = require( 'query-string' );
      const qs = queryString.parse( window.location.search );
      const autoplay = qs.autoplay;
      const autoOpen = qs['show-video'];

      if( autoplay ) {
        setIsShowingPreview( true );
      }
      else if( autoOpen ) {
        setIsOpenPreview( true );
      }

    }
  }, [] );





  /* ------ = Output. = --------------------------------------------------------------------- */
  return(

    <article
    className="Research"
    >

      <SEO
      title={ article.title }
      path={ path }
      { ...article.meta }
      />

      { !! article.intro &&

        <header>

          <Image
          className="Research__img"
          data={ !! article.intro.background ? article.intro.background.asset : null }
          />

          <Container
          className="Research__top"
          size="lg"
          >
            <Container size="sm">
              <div className="Research__top-prehding h-serif-i-md">
                { article.intro.preheading }
              </div>
              <h1 className="Research__top-hding h-xl">
                { article.title }
              </h1>
              <p className="Research__top-summary p-xl">
                { article.intro.summary }
              </p>


            </Container>

          </Container>

        </header>

      }


      <div className="Research__shareside d-none d-lg-block">
        <div className="inner">
          <ShareButtons post={ article } sharing={ sharing }/>
        </div>
      </div>

      <Container
      className="Research__content p-lg"
      size="md"
      >
        <Container size="md">

          { !! article.download &&

            <div className="Research__download">
              <div className="Research__download-topimg d-md-none">
                <Image
                alt={ `Image of ${article.name } PDF` }
                data={ !! article.download.image ? article.download.image.asset : null }
                />
              </div>
              <div className="Research__download-content">
                <h2 className="hding h-md">
                  { article.download.headline }
                </h2>
                <div className="p-lg">
                  <Image
                  className="d-none d-md-inline-block"
                  alt={ `Image of ${article.name } PDF` }
                  data={ !! article.download.image ? article.download.image.asset : null }
                  />
                  <BlockContent blocks={ article.download.text } />
                </div>
                <a
                href={ article.download.asset.asset.url }
                className="Btn is-blue is-lg"
                target="_blank"
                onClick={ e => {

                  if( typeof window !== 'undefined' ) {
                    trackCustomEvent( {
                      category: 'Research',
                      action: 'Downloaded Research Asset',
                      label: window.location.pathname,
                    } );
                  }

                } }
                >
                  <span className="Btn__txt">{ article.download.ctaText }</span>
                </a>
                <div className="Research__download-bottom d-none">
                  <WithBGImage
                  className="img"
                  aria-hidden={ true }
                  image={ {
                    data: !! article.download.image ? article.download.image.asset : null
                  } }
                  />
                  <a
                  href={ article.download.asset.asset.url }
                  className="Btn cta is-blue is-lg"
                  >
                    <span className="Btn__txt">{ article.download.ctaText }</span>
                  </a>
                </div>
              </div>

            </div>
          }

          { !! article.video &&


            <div className="Research__vids">
              <div className="row align-items-center">

                <div className="Research__vids-content col-md-6">
                  <h2 className="h-md">{ article.video.heading}</h2>
                  <div className="p-lg">
                    <BlockContent blocks={ article.video.blurb } />
                    <button
                    className="cta h-serif-i-md"
                    onClick={ () => {
                      setIsShowingVideo( true );
                      if( typeof window !== 'undefined' ) {
                        trackCustomEvent( {
                          category: 'Research',
                          action: 'Clicked watch full video',
                          label: window.location.pathname,
                        } );
                      }
                    } }
                    >
                      <i className="d-md-none"/><span>{ article.video.ctaText }</span>
                    </button>
                  </div>
                </div>

                <div
                className="Research__vids-preview col-md-6"
                aria-hidden={ true }
                >
                  <button
                  className="thumb d-none d-md-block"
                  onClick={ () => {
                    setIsShowingPreview( true );
                    if( typeof window !== 'undefined' ) {
                      trackCustomEvent( {
                        category: 'Research',
                        action: 'Clicked watch preview',
                        label: window.location.pathname,
                      } );
                    }
                  } }
                  >
                    <Image
                    data={ !! article.video.previewThumb ? article.video.previewThumb.asset : null }
                    alt="Click to watch video"
                    />
                    <span className="text">Click to watch { article.video.previewLength } preview</span>
                  </button>
                </div>

              </div>

              { typeof window !== 'undefined' &&

                <>

                  <ModalVideo
                  isOpen={ isOpenPreview }
                  isPlaying={ isShowingPreview }
                  url={ article.video.preview }
                  onClose={ () => {
                    setIsShowingPreview( false );
                    setIsOpenPreview( false );
                  } }
                  />

                  <ModalVideo
                  isPlaying={ isShowingVideo }
                  url={ article.video.full }
                  onClose={ () => setIsShowingVideo( false ) }
                  />

                </>

              }

            </div>

          }

          { !! chartProps &&

            <div className="Research__charts">
              <LoadWhenVisible>
                <h2 className="h-lg">{ article.charts.lineChart.headline }</h2>
                { !! article.charts.lineChart.subheadline &&
                  <p className="p-lg">{ article.charts.lineChart.subheadline }</p>
                }
                <LineChart { ...chartProps } />
                <div className="Research__charts-foot">
                  <BlockContent blocks={ article.charts.lineChart.footnote } />
                </div>
              </LoadWhenVisible>
            </div>
          }

          { !! article.optionalDownload && !! ( article.optionalDownload.image || {} ).asset && 

            <div className="Research__download"
            style={ {
              marginTop: '-1.5rem',
              marginBottom: '4rem'
            } }
            >
              <div className="Research__download-topimg d-md-none">
                <Image
                data={ !! article.optionalDownload.image ? article.optionalDownload.image.asset : null }
                />
              </div>
              <div className="Research__download-content">
                <h2 className="hding h-md">
                  { article.optionalDownload.headline }
                </h2>
                <div className="p-lg">
                  <Image
                  className="d-none d-md-inline-block"
                  data={ !! article.optionalDownload.image ? article.optionalDownload.image.asset : null }
                  />
                  <BlockContent blocks={ article.optionalDownload.text } />
                </div>
                <a
                href={ article.optionalDownload.asset.asset.url }
                className="Btn is-blue is-lg"
                target="_blank"
                onClick={ e => {

                  if( typeof window !== 'undefined' ) {
                    trackCustomEvent( {
                      category: 'Research',
                      action: 'Downloaded Research Asset',
                      label: window.location.pathname,
                    } );
                  }

                } }
                >
                  <span className="Btn__txt">{ article.optionalDownload.ctaText }</span>
                </a>
                <div className="Research__download-bottom d-none">
                  <WithBGImage
                  className="img"
                  aria-hidden={ true }
                  image={ {
                    data: !! article.optionalDownload.image ? article.optionalDownload.image.asset : null
                  } }
                  />
                  <a
                  href={ article.optionalDownload.asset.asset.url }
                  className="Btn cta is-blue is-lg"
                  >
                    <span className="Btn__txt">{ article.optionalDownload.ctaText }</span>
                  </a>
                </div>
              </div>

            </div>
          }

          { !! article.deeper &&

            <div className="Research__deeper">

              <h2 className="Research__deeper-hding h-md">
                { article.deeper.heading }
              </h2>

              <div className="row">
                { !! article.deeper.boxes.length && article.deeper.boxes.map( box => (

                  <div className="Research__deeper-box col-md-6">
                    <h3 className="hding h-serif-i-md">
                        { box.heading }
                      </h3>
                    <Image
                    data={ !! box.image ? box.image.asset : null }
                    />

                    <p className="p-md">
                      { box.text }
                    </p>

                    <a
                    className="Btn is-blue"
                    href={ box.url }
                    target="_blank"
                    >
                      <span className="Btn__txt">
                        { box.ctaText }
                      </span>
                    </a>
                  </div>

                ) ) }
              </div>


            </div>

          }

          <div className="Research__sharebottom d-lg-none">
            <h3 className="h-serif-i-md">Share</h3>
            <ShareButtons post={ article } sharing={ sharing }/>
          </div>

          { !! article.disclaimers &&

            <div className="Research__disclaimers">
              <BlockContent blocks={ article.disclaimers.message } />
            </div>
          }


        </Container>
      </Container>

    </article>

  );

};